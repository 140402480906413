import imagesLoaded from "imagesloaded";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import configAnimation, { anim_fadeBottom, anim_fade } from "./config/animation";

export default function Universal() {
    // Title
    try {
        gsap.utils.toArray(".reveal-char").forEach((el, i) => {
            const char = el.querySelectorAll(".char");
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.from(char, {
                rotationX: -40,
                yPercent: 100,
                stagger: 0.025,
                ease: "expo.out",
                force3D: true
            });
        });
    } catch (error) {}


    try {
        gsap.utils.toArray(".reveal-line").forEach((el, i) => {
            const line = el.querySelectorAll(".line");
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.from(line, {
                yPercent: 100,
                stagger: 0.12,
                force3D: true
            });
        });
    } catch (error) {}

    try {
        gsap.utils.toArray(".fade").forEach((el, i) => {
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.from(el, anim_fade);
        });
    } catch (error) {}

    try {
        gsap.utils.toArray(".fade-bottom").forEach((el, i) => {
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.from(el, anim_fadeBottom);
        });
    } catch (error) {}

    try {
        gsap.utils.toArray(".fade-bottom-ft").forEach((el, i) => {
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.fromTo(el, {
                y: 40,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                ease: "expo.out",
                stagger: configAnimation.global_stagger - 0.05
            });
        });
    } catch (error) {}

    try {
        gsap.utils.toArray(".reveal-image").forEach((el, i) => {
            const imgLoad = imagesLoaded(el);
            const elRevealimg_img = el.querySelector("img");
            imgLoad.on("always", () => {
                const tl_universal = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: "top bottom"
                    },
                });

                tl_universal.set(
                    el, {
                        autoAlpha: 1,
                    });

                tl_universal.from(
                    el, {
                        yPercent: 100,
                        duration: configAnimation.global_duration,
                    });

                tl_universal.from(
                    elRevealimg_img, {
                        yPercent: -100,
                        scale: 1.4,
                        duration: configAnimation.global_duration,
                    }, 0);
            });
        });
    } catch (error) {}

    try {
        gsap.utils.toArray(".stretch-y").forEach((el, i) => {
            const imgLoad = imagesLoaded(el);
            imgLoad.on("always", () => {
                const tl_universal = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                    },
                });

                tl_universal.from(
                    el, {
                        transformOrigin: "top",
                        scaleY: 1.8,
                        y: 80,
                        opacity: 0,
                        duration: configAnimation.global_duration,
                        ease: "expo.out"
                    });
            });

        });
    } catch (error) {}

    try {
        gsap.utils.toArray(".reveal-char-rotate").forEach((el, i) => {
            const char = el.querySelectorAll(".char");

            // On scroll trigger
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                },
            });

            tl_universal.from(
                char, {
                    transformOrigin: "50% 50%",
                    transform: "translate3d(0px, 66.6667px, -66.6667px)",
                    rotateX: -90,
                    opacity: 0,
                    stagger: 0.04,
                    duration: configAnimation.global_duration,
                    ease: "expo.out"
                });
        });
    } catch (error) {}


    try {
        gsap.utils.toArray(".parallax .parallax-image").forEach((section, i) => {
            gsap.set(section, {
                yPercent: -30
            });

            gsap.to(section, {
                scrollTrigger: {
                    onUpdate: (self) => {
                        gsap.set(section, {
                            yPercent: self.progress * 50 - 30
                        });
                    },
                    trigger: section,
                    start: "top bottom",
                    end: "200% center",
                    once: false,
                    scrub: true
                }
            });

            gsap.from(section, {
                scale: 1.25,
                duration: 2,
                ease: "expo.out",
                scrollTrigger: {
                    trigger: section,
                }
            });
        });
    } catch (error) {}


    try {
        gsap.utils.toArray(".clip-circle").forEach((el, i) => {
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el
                }
            })

            tl_universal.fromTo(el, {
                clipPath: "circle(0% at 30% 50%)"
            }, {
                clipPath: "circle(50% at 50% 50%)",
            });
        });
    } catch (error) {

    }
}