// Dependency
import Loading from "./loading";
import Locomotive, { locoScroll } from './locomotive';
import Default from './config/default';

import Universal from "./universalanim";
import Credit from "./credit";
import Home from "./home";

function Init() {
    Home();
    Universal();
    locoScroll.update();
}

function First() {
    Default();
    Locomotive();

    Loading(() => {
        Init();
    });
}

First();

Credit();