import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(ScrollTrigger, SplitText);

export default function Home() {
    try {
        const tl_home = gsap.timeline({
            delay: 1.2,
        });
        tl_home.fromTo(".intro-img", {
            yPercent: 100,
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)'
          }, {
            yPercent: 0,
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
          });

          tl_home.from(".intro-title .char", {
            rotationX: -40,
            yPercent: 100,
            stagger: 0.025,
            ease: "expo.out",
            force3D: true
        }, +0.2);


        About();
        Services();
    } catch (error) {
    }
}

function About() {
    try {
        const tlAbout = gsap.timeline({
            defaults: {
                ease: "none"
            },
            scrollTrigger: {
                trigger: ".about-wrap",
                start: "top bottom",
                end: "bottom top",
                scrub: true
            }
        });
    
        tlAbout.to(".about-img", {
            yPercent: -80,
            rotation: 10
        });   
    } catch (error) {
    }

    ScrollTrigger.matchMedia({
        // desktop
        '(min-width: 768px)': function() {
            const elLines = new SplitText(".about-desc-text", {
                type: "lines",
                linesClass: "line",
            });
    
            gsap.utils.toArray(elLines.lines).forEach((el, i) => {
                let wrapper = document.createElement('div');
                wrapper.classList.add("masking-text");
                el.parentNode.insertBefore(wrapper, el);
                wrapper.appendChild(el);
            });
    
            const tlDesc = gsap.timeline({
                scrollTrigger: {
                    trigger: ".about-desc"
                }
            });
    
            tlDesc.from(elLines.lines, {
                yPercent: 100,
                stagger: 0.12,
                force3D: true
            });
        },
        // mobile
        '(max-width: 767px)': function() {
            const tlDesc = gsap.timeline({
                scrollTrigger: {
                    trigger: ".about-desc"
                }
            });

            tlDesc.from(".about-desc-text", {
                y: 40,
                opacity: 0,
            });
        }
    });
}

function Services() {
    try {
        gsap.utils.toArray(".reveal-line-top").forEach((el, i) => {
            const line = el.querySelectorAll(".line");
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: "top bottom"
                }
            })

            tl_universal.from(line, {
                yPercent: 100,
                stagger: 0.12,
                force3D: true
            });
        });
    } catch (error) {}

    try {
        gsap.utils.toArray(".fade-bottom-top").forEach((el, i) => {
            const tl_universal = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: "top bottom"
                }
            })

            tl_universal.from(el, {
                y: 40,
                opacity: 0,
                stagger: 0.10
            });
        });
    } catch (error) {}
}